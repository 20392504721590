import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { summarizer, findOnArray } from 'utils/functions';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog, useModal } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import LançamentoModal from './Modals/LançamentoModal';
import ChequeModal from './Modals/ChequeModal';
import EditModal from './Modals/EditModal';
import moment from 'moment';

const Baixa = () => {
  const titulo = 'Baixa de Pendências';
  const navigate = useNavigate();
  const { type, id } = useParams();
  const { state } = useLocation();
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { postBaixa, postLoading, getLoading, getPendencias, pendencias } =
    useContext(PendênciasContext);
  const { drops } = useContext(DropsContext);
  const defaultValues = {
    desconto1_pc: '',
    desconto_vlr: '',
    dtlanc: moment().format('YYYY-MM-DD'),
    financeiros: [],
    lancamentos: [],
  };
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const isRecebimento = state?.recebimentos?.length;
  const isPagamento = state?.pagamentos?.length;
  const isCheque = state?.cheques?.length;

  useEffect(() => {
    if (Boolean(id)) {
      getPendencias(id);
    } else if (isRecebimento) {
      setValue(
        'financeiros',
        state?.recebimentos?.map((f) => ({
          id: f?.id,
          entidade_id: f?.entidade_id,
          entidade: f?.entidade,
          documento: f?.documento,
          forma_pagto_id: f?.forma_pagto_id,
          forma_pagto: f?.forma_pagto,
          parcela: f?.parcela,
          valor: f?.valor_pendente,
          valor_max: f?.valor_pendente,
        }))
      );
    } else if (isPagamento) {
      setValue(
        'financeiros',
        state?.pagamentos?.map((f) => ({
          id: f?.id,
          entidade_id: f?.entidade_id,
          entidade: f?.entidade,
          documento: f?.documento,
          forma_pagto_id: f?.forma_pagto_id,
          forma_pagto: f?.forma_pagto,
          parcela: f?.parcela,
          valor: f?.valor_pendente,
          valor_max: f?.valor_pendente,
        }))
      );
    } else if (isCheque) {
      setValue(
        'financeiros',
        state?.cheques?.map((f) => ({
          id: f?.id,
          entidade_id: f?.entidade_id,
          entidade: f?.entidade,
          documento: f?.documento,
          forma_pagto_id: f?.forma_pagto_id,
          forma_pagto: f?.forma_pagto,
          parcela: f?.parcela,
          valor: f?.valor,
          valor_max: f?.valor,
        }))
      );
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (!Boolean(state)) {
      setValue('financeiros', pendencias);
    }
  }, [pendencias]);

  const onEdit = (values) => {
    const editedRows = watch('financeiros')?.map((c) => {
      if (values?.id === c?.id) {
        if (values?.type === 'PARC') {
          if (values?.valor_total > c?.valor_max) {
            return { ...c, valor: c?.valor_max, outros: 0 };
          } else {
            return { ...c, valor: values?.valor_total, outros: 0 };
          }
        }
        if (values?.type === 'DESC') {
          const outros = Number(
            ((values?.valor_total || 0) - (c?.valor || 0))?.toFixed(2)
          );
          return { ...c, outros };
        }
      } else {
        return c;
      }
    });
    setValue('financeiros', editedRows);
    const desconto_vlr = parseFloat(
      summarizer(editedRows, 'outros').toFixed(2)
    );
    const desconto1_pc = parseFloat(
      ((desconto_vlr * -100) / summarizer(editedRows, 'valor')).toFixed(3)
    );
    if (Boolean(desconto_vlr) && Boolean(desconto1_pc)) {
      setValue('desconto_vlr', desconto_vlr);
      setValue('desconto1_pc', desconto1_pc);
    }
    closeModal();
  };

  const onSubmit = ({ financeiros, lancamentos, dtlanc, desconto_vlr }) => {
    const operacao = type?.toUpperCase();

    const data = {
      dtlanc,
      operacao,
      lancamentos,
      financeiros,
      outros: desconto_vlr,
    };

    postBaixa({ data, cb: () => navigate(-1) });
  };

  const getHaver = () => {
    const valor_financeiros = summarizer(watch('financeiros'), 'valor') || 0;
    const valor_lancamentos =
      summarizer(
        watch('lancamentos')?.filter((f) => !Boolean(f?.compensacoes?.length)),
        'valor'
      ) || 0;
    const desconto_vlr = watch('desconto_vlr') || 0;

    return valor_lancamentos - valor_financeiros - desconto_vlr;
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  rows={watch('financeiros')}
                  columns={[
                    {
                      field: 'entidade',
                      headerName: 'Entidade',
                      flex: 5,
                      sortable: false,
                    },
                    {
                      field: 'documento',
                      headerName: 'Documento',
                      flex: 2,
                      sortable: false,
                    },
                    {
                      field: 'forma_pagto',
                      headerName: 'Forma de Pagamento',
                      flex: 2,
                      sortable: false,
                    },
                    {
                      field: 'parcela',
                      headerName: 'Parcela',
                      type: 'number',
                      flex: 2,
                      sortable: false,
                    },
                    {
                      field: 'valor',
                      headerName: 'Valor',
                      type: 'number',
                      flex: 2,
                      sortable: false,
                      valueFormatter: ({ value }) =>
                        `${(value || 0)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}`,
                    },
                    {
                      field: 'outros',
                      headerName: 'Descontos / Acréscimos',
                      type: 'number',
                      flex: 2,
                      sortable: false,
                      valueFormatter: ({ value }) =>
                        `${(value || 0)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}`,
                    },
                    {
                      field: 'actions',
                      headerName: 'Ações',
                      type: 'actions',
                      flex: 1,
                      getActions: ({ row }) => [
                        <GridActionsCellItem
                          icon={<Icon>edit</Icon>}
                          label="Editar"
                          onClick={() =>
                            openModal(
                              <EditModal item={row} onSubmit={onEdit} />
                            )
                          }
                        />,
                      ],
                    },
                  ]}
                  hideFooter
                  autoHeight
                  density="compact"
                  disableSelectionOnClick
                  disableColumnMenu
                  showCellRightBorder
                  showColumnRightBorder
                  localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {summarizer(watch('financeiros'), 'valor')?.toLocaleString(
                    'pt-br',
                    { style: 'currency', currency: 'BRL' }
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputMask
                  name="dtlanc"
                  control={control}
                  label="Data de Lançamento"
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputMask
                  name="desconto1_pc"
                  control={control}
                  label="% Desconto"
                  disabled={Boolean(summarizer(watch('financeiros'), 'outros'))}
                  onBlur={() => {
                    const desconto_vlr =
                      (summarizer(watch('financeiros'), 'valor') *
                        watch('desconto1_pc')) /
                      -100;
                    setValue(
                      'desconto_vlr',
                      parseFloat(desconto_vlr.toFixed(2)) || ''
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputMask
                  name="desconto_vlr"
                  control={control}
                  label="Valor Desconto"
                  disabled={Boolean(summarizer(watch('financeiros'), 'outros'))}
                  onBlur={() => {
                    const desconto1_pc =
                      (watch('desconto_vlr') * -100) /
                      summarizer(watch('financeiros'), 'valor');
                    setValue(
                      'desconto1_pc',
                      parseFloat(desconto1_pc.toFixed(2)) || ''
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {(
                    summarizer(watch('financeiros'), 'valor') +
                    (watch('desconto_vlr') || 0)
                  )?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    openDialog(
                      <LançamentoModal
                        isRecebimento={isRecebimento}
                        isPagamento={isPagamento}
                        isCheque={isCheque}
                        financeiros={watch('financeiros')}
                        onSubmit={(v) =>
                          setValue('lancamentos', [...watch('lancamentos'), v])
                        }
                        valor={
                          summarizer(watch('financeiros'), 'valor') +
                          (watch('desconto_vlr') || 0) -
                          summarizer(watch('lancamentos'), 'valor')
                        }
                      />,
                      'Lançamento'
                    )
                  }
                >
                  Lançar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Forma de Pagamento</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {watch('lancamentos')?.map((item, index) => (
                        <TableRow key={index?.toString()}>
                          <TableCell>{item?.forma_pagto}</TableCell>
                          <TableCell align="right">
                            {item?.valor?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() =>
                                setValue(
                                  'lancamentos',
                                  watch('lancamentos')?.filter(
                                    (_, i) => i !== index
                                  )
                                )
                              }
                            >
                              <Delete />
                            </IconButton>
                            {!Boolean(isPagamento) &&
                              Boolean(item?.cheques) &&
                              findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'modalidade'
                              ) === '02-CHEQUE' && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    openDialog(
                                      <ChequeModal
                                        item={item}
                                        isRecebimento={isRecebimento}
                                        isPagamento={isPagamento}
                                        isCheque={isCheque}
                                        onSubmit={(v) => {
                                          const newLanc = watch(
                                            'lancamentos'
                                          )?.map((l, i) =>
                                            i === index ? v : l
                                          );
                                          setValue('lancamentos', newLanc);
                                        }}
                                      />,
                                      'Editar Cheques'
                                    )
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle1" sx={styles?.textBold}>
                  Total Informado:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant="subtitle1" sx={styles?.textBold}>
                  {summarizer(watch('lancamentos'), 'valor')?.toLocaleString(
                    'pt-br',
                    { style: 'currency', currency: 'BRL' }
                  )}
                </Typography>
              </Grid>
              {getHaver() > 0 && (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle1">Haver a gerar:</Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Typography variant="subtitle1">
                      {getHaver()?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  loading={postLoading}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Baixa;
